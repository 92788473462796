import 'shared'

import { Dropdown } from 'bootstrap'

document.addEventListener('DOMContentLoaded', () => {
  $('ul.toggable_menu > li > span').on('click', function(e){
    let li = $(this).parents('li');
    $(li).toggleClass('open');
    $(li).toggleClass('closed');    
    $(li).find('ul').toggle();                        
  });
  
  $('ul.toggable_menu ul.subcategories > li.active').each(function(){
    $(this).parents('li').find('span').click();
  });

  $('select#year').on('change', function(){
    $(this).parents('form').submit();
  })

  var dropdownElementList = [].slice.call(document.querySelectorAll('[data-bs-toggle=dropdown]'))
  var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
    return new Dropdown(dropdownToggleEl)
  })
})
