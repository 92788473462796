import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import 'bootstrap/dist/css/bootstrap'
import '@fortawesome/fontawesome-free/css/all.css'

require("jquery")

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

document.addEventListener('DOMContentLoaded', () => {
  //console.log('YEAH! You are in shared.js')

  // Video downloads dropdown:
  if($('a.downloads').length > 0) { 
    $('a.downloads').on('click', function(e){
      e.preventDefault();
      $('a.embed_link').removeClass('active');
      $('#video_embed_container').hide();

      $('ul.download_links').toggle();   
      if ($('ul.download_links').is(":visible")) {
        $('a.downloads').addClass('active');
      } else {
        $('a.downloads').removeClass('active');
      }
    });                             
    
    $(window).unload(function(){
      $('a.downloads').removeClass('active');
      $('ul.download_links').hide();      
    })
  }     

  if ($('a.embed_link').length > 0) {
    $('a.embed_link').click(function(e){
      e.preventDefault();
      $(this).toggleClass('active');
      $("#video_embed_container").toggle();
      $("#video_embed").focus();
      $("#video_embed").select();
    });
  }
});
